import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "how-to-use-webhooks-to-respond-to-bank-statements-api-events",
      "style": {
        "position": "relative"
      }
    }}>{`How to Use Webhooks to Respond to Bank Statements API Events`}<a parentName="h1" {...{
        "href": "#how-to-use-webhooks-to-respond-to-bank-statements-api-events",
        "aria-label": "how to use webhooks to respond to bank statements api events permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h1>
    <p>{`A webhook is an HTTP endpoint with access to your backend that receives events from the Talefin’s Bank Statements API. `}</p>
    <p>{`Webhooks allow you to be notified about events such as:`}</p>
    <ul>
      <li parentName="ul">{`Updates on a crawler job status (`}<inlineCode parentName="li">{`crawler.lifecycle_update`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`The completion of an analysis of a bank account (`}<inlineCode parentName="li">{`analysis.report_ready`}</inlineCode>{`) `}</li>
    </ul>
    <p>{`You will need access to the Talefin Bank Statements Dashboard to set up a webhook. Talefin will provide a Dashboard username and password after provisioning your account.  For testing purposes and to see the flow of the events being sent to your system, we recommend using a webhook from `}<a parentName="p" {...{
        "href": "https://webhook.site"
      }}>{`webhook.site`}</a>{`. To do that, copy the link from webhook.site and add it to your webhook via the Bank Statements Dashboard.  In production, you will have to provide the link to your webhook to us.`}</p>
    <h2 {...{
      "id": "bank-statements-api-events-table",
      "style": {
        "position": "relative"
      }
    }}>{`Bank Statements API Events Table`}<a parentName="h2" {...{
        "href": "#bank-statements-api-events-table",
        "aria-label": "bank statements api events table permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`The table below describes the events sent by the Bank Statements API in the order they are sent. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Event`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`crawler.lifecycle_updated (status QUEUED)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent during the selection of the bank institution by the client.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`crawler.lifecycle_updated (status PROCESSING)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This is event is sent while loading the selected bank institution crawler.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`crawler.lifecycle_updated (status AUTH)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This is event is sent when the authentication credentials collection fields are presented to the client. This stage is bypassed during application refreshes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`crawler.lifecycle_updated (status TIMEDOUT)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent when the client does not supply their authentication credentials within a 10-minute period of the authentication credentials collection fields being presented to the client.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`crawler.lifecycle_updated (status FAILED)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent when the crawler raises an expected error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`crawler.lifecycle_updated (status RELEASED)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the client has provided their authentication credentials and the crawler is accessing their online bank portal to download their transaction data, balance information, etc.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`crawler.lifecycle_updated (status DIED)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent when the crawler raises an unexpected error.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`crawler.seed_created`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the client authentication credentials and account selection (consent) have been encrypted and stored. This event is bypassed with application refreshes. The seed should be recorded somewhere secure in your system as it will be required to initiate a refresh.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`account.numbers_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent during additional collection of account BSB and number. It is sent just after the `}<inlineCode parentName="td">{`crawler.lifecycle_updated (status RELEASED)`}</inlineCode>{`.  and releases the BSB and account numbers for all accounts being processed. `}<br />{` `}<strong parentName="td">{`Example`}</strong>{`: `}{`[{"bsb": "123456", "number": "1234567"}, {"bsb": "123456", "number": "1234567"}]`}{`. It requires the balance collection service parameter.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`account.balances_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent during additional collection of current and available account balances. It requires the balances collection service parameter. It is sent after `}<inlineCode parentName="td">{`account.numbers_ready`}</inlineCode>{` and releases the account balances for use. `}<br />{` `}<strong parentName="td">{`Example`}</strong>{`: `}{`[{"bsb": "123456", "number": "1234567", "available": "3.98", "balance": "3.98"}, {"bsb": "123456", "number": "1234567", "available": "3.98", "balance": "3.98"}]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`account.owner_info`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent when account owner information is retrieved by the crawler for each account analysis. The account owner information allows for verification of the owner's name, address and mobile that is registered for each bank account. This event requires the account owner collection service parameter.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`crawler.lifecycle_updated (status COMPLETE)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after all the logic checks on transaction data, additional collection and crawler seed created.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`client.submission_complete`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the client has finalised their multiple institution submission through the pressing of the application complete button in the Iframe. It requires the `}<inlineCode parentName="td">{`multiple=true`}</inlineCode>{` collection service parameter.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`analysis.report_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the generation of the account analysis once the crawler has retrieved the account transaction data. You can use this event to know when to collect the report JSON, and eStatement/s for a specific account.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`analysis.html_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the analysis HTML for a specific account is ready to be collected.  It requires the vendor setting print html checked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`analysis.pdf_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the analysis PDF for a specific account is ready to be collected. It requires the vendor setting print pdf checked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`summary.report_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the generation of the application summary, which is a JSON file containing all the metrics for a particular application.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`summary.html_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the generation of the summary HTML and it requires the vendor setting print html checked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`summary.pdf_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the generation of the summary PDF and it requires the vendor setting print pdf checked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`summary.pdf_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the generation of the summary PDF and it requires the vendor setting print pdf checked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`application.combined_pdf_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent  after the generation of the combined analysis pdf document, which is a combination of analyses and summary PDFS into a single PDF file. It requires the vendor setting uses combined analysis pdf checked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`application.report_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the generation of the application report, `}<inlineCode parentName="td">{`report.json`}</inlineCode>{`, which include application metrics, profiles, account ids, and analysis ids. Note: this happens before PDFS and HTMLS are cached.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`application.bundle_ready`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`This event is sent after the generation of the application document bundle, `}<inlineCode parentName="td">{`bundle.json`}</inlineCode>{`, which includes all the documents, account analysis and application summary on a single JSON file for archiving purposes. You would usually fowllow this event by a single request to `}<inlineCode parentName="td">{`/api/application/{application_id}/bundle`}</inlineCode>{` which would deliver. Note: PDFs & HTML reports can be excluded from this bundle via configuration options in the dashboard but you will still need to wait for them to be cached. If your implementation is time-sensitive, use the application.report_ready to drive your decision engine then collect the PDFs individually or collectively by pulling the bundle when you get application.bundle_ready.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`In the dashboard there are two configuration options to turn on and off the analysis and eStatement PDFs & HTML is included in the report JSON and bundle JSON files.`}</p>
    <p>{`Sometimes the end-user will select the incorrect bank or change their mind. If a crawler is simply abandoned you will receive a TimeOut error from the crawler.
This can cause a false error; to handle this case there is an Exit signal that can be sent to the crawler instance which will initiate a graceful shutdown.`}</p>
    <h2 {...{
      "id": "example-html-to-initiate-this-endpoint",
      "style": {
        "position": "relative"
      }
    }}>{`Example HTML to initiate this endpoint`}<a parentName="h2" {...{
        "href": "#example-html-to-initiate-this-endpoint",
        "aria-label": "example html to initiate this endpoint permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <deckgo-highlight-code {...{
      "highlight-lines": "undefined"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<form action="io" method="POST">
    <input type="hidden" name="csrfmiddlewaretoken" value="[some_token]">
    <input type="hidden" name="exit" value="exit">
    <input type="submit" value="Exit" class="button-primary mt-3 px-3">
</form>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`All you need to provide is an element called “exit” with the value of “exit” and the crawler will be shut down.`}</p>
    <h2 {...{
      "id": "event-payload-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Event Payload Examples`}<a parentName="h2" {...{
        "href": "#event-payload-examples",
        "aria-label": "event payload examples permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <deckgo-highlight-code {...{
      "highlight-lines": "undefined"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
  "event": "application.bundle_ready",
  "payload": {
      "id": 961194,
      "vendor_specific_id": "200120c",
      "timestamp": "2020-01-21T12:52:23.480539+11:00",
      "crawler": [
      {
          "uuid": "8bb8ba28-9f5f-408b-844c-16cc400b1cfd",
          "application": 961194,
          "bank_id": 130,
          "timestamp": "2020-01-21T12:52:23.486783+11:00",
          "status": "COMPLETED"
      }
      ],
      "full_name": "Mickey-Mouse",
      "email": "mickey@mice.com",
      "mobile": "0412345678",
      "finalised": true,
      "analysed": true
  }
}

{
  "event": "summary.report_ready",
  "payload": {
      "id": 961194,
      "vendor_specific_id": "200120c",
      "timestamp": "2020-01-21T12:52:23.480539+11:00",
      "crawler": [
      {
          "uuid": "8bb8ba28-9f5f-408b-844c-16cc400b1cfd",
          "application": 961194,
          "bank_id": 130,
          "timestamp": "2020-01-21T12:52:23.486783+11:00",
          "status": "COMPLETED"
      }
      ],
      "full_name": "Mickey-Mouse",
      "email": "mickey@mice.com",
      "mobile": "0412345678",
      "finalised": true,
      "analysed": true
  }
}

{
  "event": "application.report_ready",
  "payload": {
      "id": 961194,
      "vendor_specific_id": "200120c",
      "timestamp": "2020-01-21T12:52:23.480539+11:00",
      "crawler": [
      {
          "uuid": "8bb8ba28-9f5f-408b-844c-16cc400b1cfd",
          "application": 961194,
          "bank_id": 130,
          "timestamp": "2020-01-21T12:52:23.486783+11:00",
          "status": "COMPLETED"
      }
      ],
      "full_name": "Mickey-Mouse",
      "email": "mickey@mice.com",
      "mobile": "0412345678",
      "finalised": true,
      "analysed": true
  }
}

{
  "event": "analysis.report_ready",
  "payload": {
      "id": 22749,
      "time": "2020-01-21T12:52:47.779186+11:00",
      "vendor_specific_id": "200120c"
  }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2 {...{
      "id": "example-account-owner-payloads",
      "style": {
        "position": "relative"
      }
    }}>{`Example Account Owner Payloads`}<a parentName="h2" {...{
        "href": "#example-account-owner-payloads",
        "aria-label": "example account owner payloads permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <h3 {...{
      "id": "success",
      "style": {
        "position": "relative"
      }
    }}>{`Success`}<a parentName="h3" {...{
        "href": "#success",
        "aria-label": "success permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <deckgo-highlight-code {...{
      "highlight-lines": "undefined"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
    "status": "success",
    "fileLocation": "account_owner_info-325185-03531384.json",
    "messageType": "account_owner_info",
    "errorMessage": "",
    "accountOwnerInfo": {
        "bsb": "325185",
        "number": "03531384",
        "address": [
        {
            "addressLine1": "29 SOUTH TERRACE",
            "addressLine2": "QUORN SA 5433",
            "streetNumber": "29",
            "streetName": "SOUTH",
            "streetType": "TERRACE",
            "townSuburb": "QUORN",
            "state": "SA",
            "postCode": "5433",
            "country": "AU"
        }
        ],
        "owners": ["MISS T J COULTHARD"],
        "email": [],
        "phone": []
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3 {...{
      "id": "failed",
      "style": {
        "position": "relative"
      }
    }}>{`Failed`}<a parentName="h3" {...{
        "href": "#failed",
        "aria-label": "failed permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <deckgo-highlight-code {...{
      "highlight-lines": "undefined"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`{
    "status": "failed",
    "fileLocation": "account_owner_info-062692-44520485.json",
    "messageType": "account_owner_info",
    "errorMessage": "No eStatements available",
    "accountOwnerInfo": {
        "number": "44520485",
        "bsb": "062692",
        "address": [],
        "owners": [],
        "email": [],
        "phone": []
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2 {...{
      "id": "authentication",
      "style": {
        "position": "relative"
      }
    }}>{`Authentication`}<a parentName="h2" {...{
        "href": "#authentication",
        "aria-label": "authentication permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Authenticating with TaleFin’s API backend is done via `}<a parentName="p" {...{
        "href": "/docs/v1/hmac-authentication"
      }}>{`HMAC`}</a>{`. You will need this if you wish to collect any reports and analyses from the backend.
The tokens can be created by visiting the API documentation, or through our dashboard.`}</p>
    <h2 {...{
      "id": "ip-whitelisting",
      "style": {
        "position": "relative"
      }
    }}>{`IP Whitelisting`}<a parentName="h2" {...{
        "href": "#ip-whitelisting",
        "aria-label": "ip whitelisting permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Webhooks from TaleFin originate from the following IP addresses. These are provided so you can allow access by these addresses through a firewall you are using.`}</p>
    <p>{`Production IP address: 34.87.247.183`}</p>
    <p>{`Staging IP address: 34.151.65.130 `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      